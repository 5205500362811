.profile-pic {
  position: relative;
  display: inline-block;
}

.profile-pic:hover .edit {
  display: block;
}

.edit {
  padding-top: 7px;
  padding-right: 7px;
  position: absolute;
  right: 0;
  top: 0;
  left: 85%;
  cursor: pointer;
  /* display: none; */
}

.edit a {
  color: #000;
}
#custom-button {
  padding: 10px;
  color: white;
  background-color: #2c5fcc;
  border: 1px solid #fff;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

#custom-button:hover {
  background-color: #2c5fcc;
}

#custom-text {
  margin-left: 10px;
  font-size: 14px;
  font-family: sans-serif;
  color: #aaa;
}
.filebox {
  padding-left: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.filelabel {
  font-size: 15px;
}
.MuiInputBase-input {
  padding: 12px 10px !important;
}

.custom-textarea {
  box-sizing: border-box;
  width: 100%;
  height: 100px; /* Set a fixed height */
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: #1c2025; /* Default text color */
  background: #fff; /* Default background color */
  border: 1px solid #e5eaf2; /* Default border color */
  box-shadow: 0px 2px 2px #f3f6f9; /* Default box shadow */
  resize: none; /* Disable resizing */
}

.custom-textarea:hover {
  border-color: #3399ff; /* Border color on hover */
}

.custom-textarea:focus {
  border-color: #007fff; /* Border color on focus */
  box-shadow: 0 0 0 3px #daecff; /* Box shadow on focus */
  outline: none; /* Remove default outline */
}

/* z-index for google autocomplete dropdown */
.pac-container {
  z-index: 9999 !important; /* Higher than other UI components */
}

/* Custom Loader */
.dotsLoader {
  width: 10px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}
@keyframes l5 {
  0% {
    box-shadow: 15px 0 #000, -15px 0 #0002;
    background: #000;
  }
  33% {
    box-shadow: 15px 0 #000, -15px 0 #0002;
    background: #0002;
  }
  66% {
    box-shadow: 15px 0 #0002, -15px 0 #000;
    background: #0002;
  }
  100% {
    box-shadow: 15px 0 #0002, -15px 0 #000;
    background: #000;
  }
}
.cardBody {
  background: #f7f8fa;
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
.summary{
  font-size: 14px;
}
.evaluation{
  margin-top: 10px;
  margin-bottom: 10px;
}