.skillsgap h1 {
  font-weight: bold;
  margin: 0.5rem 0;
  text-align: center;
}
.skills-heading {
  font-size: 22px;
  font-weight: 700;
  margin: 0.5rem 0;
}
.skillsgap h2 {
  font-size: 22px;
  font-weight: 700;
  margin: 0.5rem 0;
}
.skillsgap h3 {
  font-size: 20px;
  font-weight: 600;
  margin: 1rem 0;
}
.skillsgap h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 1rem 0;
}
.skillsgap p {
  margin: 1rem 0.5rem;
}
.skillsgap p strong {
  font-size: 18px;
}
.skillsgap ul li {
  margin: 0.5rem 0 0.5rem 1.5rem;
  list-style: initial;
}
.skillsgap br {
  display: none;
}
@media (max-width: 525px) {
  .skillsgap h1 {
    font-size: 22px;
    line-height: 30px;
  }
  .skills-heading {
    font-size: 20px;
  }
  .skillsgap h2 {
    font-size: 18px;
  }
  .skillsgap h3 {
    font-size: 16px;
  }
  .skillsgap h4 {
    font-size: 16px;
  }
  .skillsgap p strong {
    font-size: 16px;
  }
}
