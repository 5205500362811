.ic_preloader_main {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.815);
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .preloader {
    width: 30vmin;
    height: 30vmin;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0.65);
    animation: spin-all calc(var(--sp) * 2) linear 0s infinite;
  }
  
  :root {
    --sp: 2.5s; /* change speed */
  }
  
  .circle {
    --in: 80%;
    --ar: #2b6be2;
    --dt: #fbc343;
    --shadow: drop-shadow(0vmin 0vmin 0.5vmin #000)
      drop-shadow(0vmin 1vmin 0.5vmin #0004);
    --cross: linear-gradient(
        0deg,
        #fff0 calc(50% - 2px),
        #000 calc(50% - 1px) calc(50% + 1px),
        #fff0 calc(50% + 2px)
      ),
      linear-gradient(
        90deg,
        #fff0 calc(50% - 2px),
        #000 calc(50% - 1px) calc(50% + 1px),
        #fff0 calc(50% + 2px)
      );
    border: 4vmin solid var(--ar);
    width: var(--in);
    height: var(--in);
    border-radius: 100%;
    position: absolute;
    box-sizing: border-box;
    border-top-color: rgba(255, 255, 255, 0);
    border-left-color: rgba(255, 255, 255, 0);
    top: 15vmin;
    right: -10vmin;
    animation: spin-bot var(--sp) ease 0s infinite;
    background-image: var(--cross),
      radial-gradient(
        var(--dt) 5.5vmin,
        rgba(255, 255, 255, 0) calc(5.5vmin + 1px)
      );
    background-repeat: no-repeat;
    background-size: 3vmin 1vmin, 1vmin 3vmin, 100% 100%;
    background-position: center center;
  }
  
  .circle:nth-child(2) {
    --in: 60%;
    top: -2vmin;
    animation: spin-top var(--sp) ease 0s infinite;
    transform: rotate(-45deg);
    background-image: var(--cross),
      radial-gradient(
        var(--dt) 1.25vmin,
        rgba(255, 255, 255, 0) calc(1.25vmin + 1px)
      );
    right: -4vmin;
    background-size: 1.4vmin 1vmin, 1vmin 1.4vmin, 100% 100%;
  }
  
  .circle:nth-child(3) {
    --in: 100%;
    top: -5vmin;
    left: -13vmin;
    transform: rotate(175deg);
    animation: spin-left var(--sp) ease calc(var(--sp) / 4) infinite;
    background-image: var(--cross),
      radial-gradient(var(--dt) 9vmin, rgba(255, 255, 255, 0) calc(9vmin + 1px));
    background-size: 5vmin 1vmin, 1vmin 5vmin, 100% 100%;
  }
  
  .circle:nth-child(4) {
    --in: 60%;
    top: 35vmin;
    left: -6vmin;
    transform: rotate(-280deg);
    animation: spin-last var(--sp) ease calc((var(--sp) / 4 + var(--sp)) * -1)
      infinite;
    background-image: var(--cross),
      radial-gradient(
        var(--dt) 2.5vmin,
        rgba(255, 255, 255, 0) calc(2.5vmin + 1px)
      );
    background-size: 2vmin 1vmin, 1vmin 2vmin, 100% 100%;
  }
  
  @keyframes spin-all {
    0% {
      transform: rotate(0deg) scale(0.65);
    }
    100% {
      transform: rotate(360deg) scale(0.65);
    }
  }
  @keyframes spin-top {
    0% {
      transform: rotate(-45deg);
    }
    100% {
      transform: rotate(315deg);
    }
  }
  @keyframes spin-bot {
    0%,
    35% {
      transform: rotate(0deg);
    }
    80%,
    100% {
      transform: rotate(-360deg);
    }
  }
  @keyframes spin-left {
    0%,
    35% {
      transform: rotate(175deg);
    }
    80%,
    100% {
      transform: rotate(535deg);
    }
  }
  @keyframes spin-last {
    0%,
    10% {
      transform: rotate(-280deg);
    }
    90%,
    100% {
      transform: rotate(-640deg);
    }
  }