.privacy_main {
  display: flex;
  padding: 0 40px;
}
@media only screen and (max-width: 767.98px) {
  .privacy_main {
    flex-direction: column;
  }
}
.section_heading {
  font-size: 24px;
  font-weight: bold;
  margin: 12px 0;
}
.privacy_main .privacy_policy_content {
  width: calc(100% - 353px);
}
@media only screen and (max-width: 1399.98px) {
  .privacy_main .privacy_policy_content {
    width: calc(100% - 254px);
  }
}
@media only screen and (max-width: 767.98px) {
  .privacy_main .privacy_policy_content {
    width: calc(100% - 0px);
  }
}
.ic_privacy_policy {
  padding: 100px 0;
  background-repeat: no-repeat;
  background-position: top center;
  background-color: #fff;
  overflow-x: hidden;
}
@media only screen and (max-width: 1650px) {
  .ic_privacy_policy {
    padding-top: 150px;
  }
}
@media only screen and (max-width: 1199.98px) {
  .ic_privacy_policy {
    padding-top: 100px;
  }
}

.privacy_policy_sidebar {
  width: 353px;
}
@media only screen and (max-width: 1399.98px) {
  .privacy_policy_sidebar {
    width: 254px;
  }
}
@media only screen and (max-width: 767.98px) {
  .privacy_policy_sidebar {
    width: 100%;
  }
}
.privacy_policy_sidebar ul {
  position: relative;
  border-left: 1px solid #d6dae1;
}
@media only screen and (max-width: 767.98px) {
  .privacy_policy_sidebar ul {
    border-left: 0;
    display: flex;
    padding-bottom: 20px !important;
    flex-wrap: wrap;
  }
}
.privacy_policy_sidebar ul li {
  list-style: none;
}
.privacy_policy_sidebar ul li a {
  font-weight: 500;
  color: #1b2533;
  padding: 12px 17px;
  display: inline-block;
  position: relative;
  transition: 0.3s;
}
@media only screen and (max-width: 767.98px) {
  .privacy_policy_sidebar ul li a {
    padding: 8px 7px;
  }
}
.privacy_policy_sidebar ul li a::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 100%;
  background-color: #2b6be2;
  transform: scaleY(0);
  transition: 0.3s;
}
@media only screen and (max-width: 767.98px) {
  .privacy_policy_sidebar ul li a::after {
    width: 100%;
    height: 1px;
    bottom: 0;
    top: unset;
  }
}
.privacy_policy_sidebar ul li a.active {
  color: #2b6be2;
  background: transparent;
}
.privacy_policy_sidebar ul li a.active::after {
  transform: scaleY(1);
}
.privacy_policy_sidebar ul li a:hover {
  color: #2b6be2;
}
.privacy_policy_sidebar ul li a:hover::after {
  transform: scaleY(1);
}
