/* // chat */
.chat-container {
  height: 65vh;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #ccc;
}
/* Handle of the scrollbar */
.chat-container::-webkit-scrollbar {
  width: 6px;
}

/* Hide the scrollbar track */
.chat-container::-webkit-scrollbar-track {
  background: transparent;
}

/* Style the scrollbar thumb */
.chat-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

/* Hover effect for the scrollbar thumb */
.chat-container::-webkit-scrollbar-thumb:hover {
  background-color: #b6b6b6;
}

.message {
  padding: 5px 10px;
  margin: 2px;
  margin-top: 8px;
  border-radius: 5px;
  word-wrap: break-word;
  max-width: 80%;
  font-size: 18px;
  line-height: 28px;
  width: max-content;
}

.message-left {
  background-color: #f0f0f0;
}

.message-right {
  background-color: #2781ea;
  color: #fff;
}

.message-bubble-right {
  width: 14px;
  height: 10px;
  background: #2781ea;
  border-radius: 0% 0% 100% 0%;
  margin-top: 8px;
  margin-left: -7px;
}
.message-bubble-left {
  width: 14px;
  height: 10px;
  background: #f0f0f0;
  border-radius: 0% 0% 0% 100%;
  margin-top: 8px;
  margin-right: -7px;
}

.right {
  justify-content: end;
  flex-direction: row-reverse;
}

.chat-container {
  height: 65vh;
  display: flex;
  flex-direction: column;
}

/* MessageInput*/
.message-input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.message-send-btn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 5px;
  right: 1.2%;
  margin-bottom: 5px;
  /* width: 30px;
  height: 30px; */
  background: linear-gradient(195deg, #49a3f1, #1a73e8);
}
.message-send-btn:hover {
  background: linear-gradient(195deg, #1a73e8, #1a73e8);
}

.truncate-line-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.message-input {
  margin: 0;
  width: 100%;
  border: 0;
  outline: none;
  resize: none;
  background-color: transparent;
  padding-right: 2.5rem;
  font-size: 16px;
  line-height: 1.5;
  max-height: 200px;
  overflow-y: scroll;
  font-family: Arial, Helvetica, sans-serif;

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: #c3c1d8 #f2fdff;

  /* Chrome, Edge, and Safari */
}

.message-input::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.message-input::-webkit-scrollbar-track {
  background: transparent; /* Makes the track invisible */
}

.message-input::-webkit-scrollbar-thumb {
  background-color: #b0b0b0; /* Default thumb color */
  border-radius: 10px; /* Rounded scrollbar thumb */
  border: 2px solid transparent; /* Gives a thin spacing around the thumb */
  background-clip: padding-box; /* Ensures proper clipping */
}

.message-input::-webkit-scrollbar-thumb:hover {
  background-color: #7d7d7d; /* Darker on hover */
}

/* Scrollbar for Firefox */
.message-input {
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: #b0b0b0 transparent; /* Thumb color and transparent track */
}

.message-input:hover {
  scrollbar-color: #7d7d7d transparent; /* Darker thumb on hover */
}

.time-color-right {
  color: #b0b0b0;
}
.time-color-left {
  color: #fff;
}

.vh-65 {
  overflow: scroll;
  height: 65vh;
}

.active-chat-color {
  background: linear-gradient(195deg, #49a3f1, #1a73e8);
  &:hover {
    background-color: #333333;
  }
  h3,
  p {
    user-select: none;
  }
}
.chat-color {
  &:hover {
    background-color: #333333;
  }
  h3,
  p {
    user-select: none;
  }
}

.chat-body-border {
  border-radius: 0px 0px 12px 12px !important;
  margin-top: -0.25rem;
}

.truncate {
  overflow: hidden;
  max-width: 20rem;
  text-overflow: ellipsis;
}

.d-flex {
  display: flex;
}
.flex-col {
  flex-direction: column;
}
.align-items-center {
  align-items: center;
}
.position-relative {
  position: relative;
}
.border {
  border: 1px solid #ccc;
}

.rounded {
  border-radius: 5px;
}
.rounded-lg {
  border-radius: 12px;
}
.w-100 {
  width: 100%;
}
.vh-65 {
  height: 65vh;
}
.overflow-hidden {
  overflow: hidden;
}
.truncate-line-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.bg-white {
  background-color: white;
}
.conversation-container {
  max-height: 60vh !important;
  overflow: auto;
}
::-webkit-scrollbar {
  width: 0.211rem;
  height: 0rem;
}
::-webkit-scrollbar-track {
  background: theme-color("site-secondary1");
}
::-webkit-scrollbar-thumb {
  background-color: #cecece; /* color of the scroll thumb */
  border-radius: 1.25rem; /* roundness of the scroll thumb */
}
