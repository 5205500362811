.pricing-plan-card {
  max-width: 320px;
  margin: auto;
  background-color: #2b6be2;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  border: 1px solid #d6dae1;
  height: 100%;
  transition: 0.3s;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.pricing-plan-card .top-section {
  padding: 24px;
}
.pricing-plan-card .top-section h2 {
  color: white;
  text-align: center;
  font-size: 28px;
  font-weight: 600;
}
.pricing-plan-card .top-section p {
  color: white;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}

.pricing-plan-card .description-section {
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
  padding: 16px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-image: url("../images/pricingBG.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

.description-section .price {
  margin-top: 12px;
  display: flex;
  justify-content: center;
  height: 42px;
}
.description-section .price h2 {
  font-size: 48px;
  font-weight: 600;
  text-align: center;
  color: white;
}
.description-section .price h4 {
  text-align: center;
  color: white;
}
.description-section .price .dollar {
  text-align: center;
  color: white;
}
.description-section .div-box {
  background: #ffffffb2;
  padding: 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  width: 94px;
  margin: auto;
  margin-top: 1rem;
}

.description-section .pricing-content {
  background-color: white;
  border-radius: 10px;
  padding: 20px 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  justify-content: start;
  min-height: 32rem;
}
.description-section .pricing-content .item {
  display: flex;
  align-items: center;
  gap: 6px;
}
.description-section .pricing-content .item p {
  font-size: 12px;
  font-weight: 500;
}

.blue-tick-icon-wrapper {
  width: 21px;
  height: 21px;
  border-radius: 50%;
}
.blue-tick-icon-wrapper img {
  width: 20px;
  height: 20px;
}

.ic-btn-primary {
  background-color: #2b6be2;
  width: 100%;
}
.ic-btn-danger {
  background-color: #f93b3b;
  width: 25%;
}

.ic-btn-go-to-pro {
  border-radius: 8px;
  padding: 1rem 8px;
  text-align: center;
  color: white;
  margin-top: 12px;
  border: none;
}
.button_cursor {
  cursor: pointer;
}
.idealFor {
  color: #2b6be2;
  font-size: 14px;
}

.pricing-button {
  position: absolute;
  width: 85%;
  left: 8%;
  bottom: 4%;
}
.ic-pricing-item .pricing-button {
  width: 100%;
  margin-top: auto;
}
.ic-pricing-item .pricing-button .ic-btn,
.ic-pricing-item .pricing-button .ic-btn-white,
.ic-pricing-item .pricing-button .ic-btn-gray {
  border: 1px solid #d6dae1;
  background-color: #eaecf0;
  color: #2b6be2;
}
.ic-pricing-item .pricing-button .ic-btn:hover,
.ic-pricing-item .pricing-button .ic-btn-white:hover,
.ic-pricing-item .pricing-button .ic-btn-gray:hover {
  background-color: #2b6be2;
  color: #fff;
  border: 1px solid #2b6be2;
}
.ic-pricing-item:hover {
  box-shadow: 0px 40px 60px 0px rgba(134, 134, 134, 0.1);
}
.ic-pricing-item:hover .ic-btn,
.ic-pricing-item:hover .ic-btn-white,
.ic-pricing-item:hover .ic-btn-gray {
  background-color: #2b6be2;
  color: #fff;
  border: 1px solid #2b6be2;
}

